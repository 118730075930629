<template>
    <div class="bg-white">
        <TransitionFade>
            <div class="flex flex-col px-8">
                <div class="w-full">
                    <!-- Logos -->
                    <header class="lg:max-w-[1280px] mx-auto">
                        <div class="p-2 flex items-center grid grid-cols-2 gap-x-3 ">
                            <div class="flex justify-start items-center">
                                <img class="h-20 md:h-20 object-fit"
                                    src="@/assets/MercadoShops/Acumbamail/Logo-acumbamail.png" alt="logo mailup" />
                                <img class="h-28 md:h-28 object-fit"
                                    src="@/assets/MercadoShops/Mailup/logo-ms.png" alt="logo mercado shops" />
                            </div>
                            <div class="flex justify-end">
                                <SelectLangs :showLangs="['es', 'pt']" />
                            </div>
                        </div>

                    </header>

                    <!-- banner marketing -->
                    <section
                        class="my-8 lg:max-w-[1024px] z-50 h-32 relative mx-auto flex flex-col justify-center items-center rounded-2xl border-2 border-black ">
                        <div class="text-center text-xl md:text-4xl font-bold py-4 px-8" v-html="$t('landingPageAcumbamail.titulo')">
                        </div>
                        <img class="w-16 md:w-32 absolute -bottom-8 left-8 md:-bottom-12 md:left-4"
                            src="@/assets/MercadoShops/Acumbamail/icono-amarillo_.png">
                    </section>


                    <!-- 50 off -->
                    <section
                        class="my-8 flex justify-center items-center mx-auto lg:max-w-[1024px] grid grid-cols-3 gap-2 mt-4 px-4">
                        <div class="col-span-3 text-center md:col-span-2 md:text-left">
                            <p>
                                <span class="text-lg lg:text-xl font-light"
                                    v-html="$t('landingPageAcumbamail.Si sos usuario de')">
                                </span>
                                <span class="text-lg lg:text-xl mx-1 font-semibold"
                                    v-html="$t('landingPageAcumbamail.Mercado Shops')">
                                </span>
                                <span class="text-lg lg:text-xl font-light"
                                    v-html="$t('landingPageAcumbamail.podes adquirir un')">
                                </span>
                                <br>
                            </p>
                            <h2 class="mb-8 text-2xl sm:text-3xl font-bold" v-html="$t('landingPageAcumbamail.50Off')">
                            </h2>
                            <a role="button"
                                href="https://setup.baymark.net/client/oauth2/redirect/mercadolibre_acumbamail/19?ignoreValidate=true"
                                target="_blank"
                                class="uppercase mt-16 shadow bg-[#255ab0] p-4 text-xl text-white font-bold rounded transition-all mt-4 rounded-xl "
                                v-html="$t('landingPageAcumbamail.btnIntegra')"></a>
                        </div>
                        <div class="col-span-3 flex justify-center md:col-span-1 md:justify-end mt-4 sm:mt-2 sm:-mr-8 md:-mt-16">
                            <img src="@/assets/MercadoShops/Acumbamail/icono-mano.png" alt="imagen descriptiva de MailUp"
                                class="w-1/2 h-1/2 md:w-max md:h-max z-0 ">
                        </div>
                    </section>

                    <section
                        class="mt-16 flex justify-center items-center mx-auto lg:max-w-[1024px] grid grid-cols-3 border-4 border-black rounded-xl sm:py-4">
                        <div
                            class="px-8 py-4 mx-8 sm:mx-0 sm:h-32 relative col-span-3 flex flex-col md:col-span-1 lg:col-span-1 text-center lg:h-32 border-b-4 sm:border-r-2 sm:border-b-0 border-black justify-center items-center">
                            <img class="w-16 md:w-16 lg:w-24 sm:absolute -top-12 lg:-top-16"
                                src="@/assets/MercadoShops/Acumbamail/Icono-final-2_cuadrado.png">
                            <p class="text-lg sm:text-sm md:text-lg lg:text-xl font-bold"
                                v-html="$t('landingPageAcumbamail.versionFreemium')"></p>
                        </div>
                        <div
                            class="px-8 py-4 mx-8 sm:mx-0 sm:h-32 relative col-span-3 flex flex-col md:col-span-1 lg:col-span-1 text-center lg:h-32 border-b-4 sm:border-b-0 sm:border-l-2 sm:border-r-2 border-black justify-center items-center">
                            <img class="w-16 md:w-16 lg:w-24 sm:absolute -top-12 lg:-top-16"
                                src="@/assets/MercadoShops/Acumbamail/icono-azul.png">
                            <p class="text-lg sm:text-sm md:text-lg lg:text-xl font-bold"
                                v-html="$t('landingPageAcumbamail.atencion')"></p>
                        </div>
                        <div
                            class="px-8 py-4 mx-8 sm:mx-0 sm:h-32 relative col-span-3 flex flex-col md:col-span-1 lg:col-span-1 text-center lg:h-32 sm:border-l-2 border-black justify-center items-center">
                            <img class="w-16 md:w-16 lg:w-24 sm:absolute -top-12 lg:-top-16"
                                src="@/assets/MercadoShops/Acumbamail/icono-amarillo_.png">
                            <p class="text-lg sm:text-sm md:text-lg lg:text-xl font-bold"
                                v-html="$t('landingPageAcumbamail.tarjeta')">
                            </p>
                        </div>
                    </section>

                    <!-- connect -->
                    <section
                        class="my-8 lg:max-w-[1024px] rounded-3xl mx-auto md:px-2 lg:px-6 text-center bg-[#FFBE57] border-4 border-black">
                        <div class="py-4 px-4 sm:px-2">
                            <p>
                                <span class="text-lg sm:text-sm md:text-lg lg:text-xl font-bold"
                                    v-html="$t('landingPageAcumbamail.conecta')">
                                </span>
                                <br />
                                <span class="text-lg sm:text-sm md:text-lg lg:text-xl font-bold"
                                    v-html="$t('landingPageAcumbamail.2000')">
                                </span>
                            </p>
                        </div>
                    </section>

                    <!-- Mejora resultados -->
                    <section
                        class="flex justify-center items-center mx-auto lg:max-w-[1280px] grid grid-cols-2 gap-2 mt-8 px-4">
                        <div class="col-span-2 text-center md:col-span-1 md:text-left">
                            <span class="text-2xl sm:text-3xl font-bold" v-html="$t('landingPageAcumbamail.resultados')">
                            </span>
                            <br />
                            <p class="text-lg lg:text-xl font-light" v-html="$t('landingPageAcumbamail.descResultados')">
                            </p>
                            <br />
                            <a role="button"
                                href="https://setup.baymark.net/client/oauth2/redirect/mercadolibre_acumbamail/19?ignoreValidate=true"
                                target="_blank"
                                class="uppercaseshadow bg-[#255ab0] mt-8 p-4 text-xl text-white font-bold rounded transition-all rounded-xl"
                                v-html="$t('landingPageAcumbamail.btnProbar')"></a>
                        </div>
                        <div class="mt-4 col-span-2 flex justify-center md:col-span-1 md:mt-0">
                            <img src="@/assets/MercadoShops/Acumbamail/Editar-plantilla.png" alt="editor de plantillas"
                                class="w-max h-max">
                        </div>
                    </section>

                    <section
                        class="flex justify-center items-center mx-auto lg:max-w-[1280px] grid grid-cols-4 gap-x-8 mt-8 px-4">
                        <div class="mt-4 col-span-4 flex flex-col md:col-span-2 lg:col-span-1 text-center lg:h-96">
                            <div class="flex justify-center mt-2">
                                <img src="@/assets/MercadoShops/Acumbamail/RELOJ.png" alt="ahorra tiempo" class="w-24 h-24">
                            </div>
                            <p class="text-2xl font-bold mt-2" v-html="$t('landingPageAcumbamail.ahorreTiempo')"></p>
                            <p class="mt-2" v-html="$t('landingPageAcumbamail.ahorreTiempoDesc')"></p>
                        </div>
                        <div class="mt-4 col-span-4 flex flex-col md:col-span-2 lg:col-span-1 text-center lg:h-96">
                            <div class="flex justify-center mt-2">
                                <img src="@/assets/MercadoShops/Acumbamail/INVERSIÓN.png" alt="ahorra tiempo"
                                    class="w-24 h-24">
                            </div>
                            <p class="text-2xl font-bold mt-2" v-html="$t('landingPageAcumbamail.retornoInversion')"></p>
                            <p class="mt-2" v-html="$t('landingPageAcumbamail.retornoInversionDesc')"></p>
                        </div>
                        <div class="mt-4 col-span-4 flex flex-col md:col-span-2 lg:col-span-1 text-center lg:h-96">
                            <div class="flex justify-center mt-2">
                                <img src="@/assets/MercadoShops/Acumbamail/TILDE.png" alt="ahorra tiempo" class="w-24 h-24">
                            </div>
                            <p class="text-2xl font-bold mt-2" v-html="$t('landingPageAcumbamail.campañasEfectivas')"></p>
                            <p class="mt-2" v-html="$t('landingPageAcumbamail.campañasEfectivasDesc')"></p>
                        </div>
                        <div class="mt-4 col-span-4 flex  flex-col md:col-span-2 lg:col-span-1 text-center lg:h-96">
                            <div class="flex justify-center mt-2">
                                <img src="@/assets/MercadoShops/Acumbamail/CORAZÓN.png" alt="ahorra tiempo"
                                    class="w-24 h-24">
                            </div>
                            <p class="text-2xl font-bold mt-2" v-html="$t('landingPageAcumbamail.comunicacion')"></p>
                            <p class="mt-2" v-html="$t('landingPageAcumbamail.comunicacionDesc')"></p>
                        </div>
                        <div class="flex justify-center col-span-4 mt-5">
                            <a role="button"
                                href="https://setup.baymark.net/client/oauth2/redirect/mercadolibre_acumbamail/19?ignoreValidate=true"
                                target="_blank"
                                class="uppercase lg:-mt-5 p-4 text-xl text-[#255ab0] font-bold transition-all mt-4 underline"
                                v-html="$t('landingPageAcumbamail.btnAcumba')"></a>
                        </div>
                    </section>

                    <section
                        class="flex justify-center items-center mx-auto lg:max-w-[1280px] grid grid-cols-4 gap-4 mt-8 px-4">

                        <div
                            class="mt-4 col-span-4 flex flex-col sm:col-span-4 md:col-span-4 lg:col-span-2 border-4 border-black rounded-xl">
                            <div class="grid grid-cols-3">
                                <div class="col-span-1 border-r-4 border-black flex items-center">
                                    <img src="@/assets/MercadoShops/Acumbamail/Icono-celeste.png" alt="ahorra tiempo">
                                </div>
                                <div class="col-span-2 p-6 flex flex-col justify-center">
                                    <p class="text-2xl font-bold" v-html="$t('landingPageAcumbamail.sincronizacion')"></p>
                                    <p class="mt-5 font-light" v-html="$t('landingPageAcumbamail.sincronizacionDesc')"></p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="mt-4 col-span-4 flex flex-col sm:col-span-4 md:col-span-4 lg:col-span-2 border-4 border-black	rounded-xl">
                            <div class="grid grid-cols-3">
                                <div class="col-span-1 border-r-4 border-black flex items-center">
                                    <img src="@/assets/MercadoShops/Acumbamail/Icono-naranja.png" alt="ahorra tiempo">
                                </div>
                                <div class="col-span-2 p-6 flex flex-col justify-center">
                                    <p class="text-2xl font-bold" v-html="$t('landingPageAcumbamail.formularios')"></p>
                                    <p class="mt-5 font-light" v-html="$t('landingPageAcumbamail.formulariosDesc')"></p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="mt-4 col-span-4 flex flex-col sm:col-span-4 md:col-span-4 lg:col-span-2 border-4 border-black	rounded-xl">
                            <div class="grid grid-cols-3">
                                <div class="col-span-1 border-r-4 border-black flex items-center">
                                    <img src="@/assets/MercadoShops/Acumbamail/Icono-amarillo.png" alt="ahorra tiempo">
                                </div>
                                <div class="col-span-2 p-6 flex flex-col justify-center">
                                    <p class="text-2xl font-bold" v-html="$t('landingPageAcumbamail.feedback')"></p>
                                    <p class="mt-5 font-light" v-html="$t('landingPageAcumbamail.feedbackDesc')"></p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="mt-4 col-span-4 flex flex-col sm:col-span-4 md:col-span-4 lg:col-span-2 border-4 border-black	rounded-xl">
                            <div class="grid grid-cols-3">
                                <div class="col-span-1 border-r-4 border-black flex items-center">
                                    <img src="@/assets/MercadoShops/Acumbamail/Icono-verde.png" alt="ahorra tiempo">
                                </div>
                                <div class="col-span-2 p-6 flex flex-col justify-center">
                                    <p class="text-2xl font-bold" v-html="$t('landingPageAcumbamail.pymes')"></p>
                                    <p class="mt-5 font-light" v-html="$t('landingPageAcumbamail.pymesDesc')"></p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <!-- banner freemium -->
                    <section
                        class="my-8 flex flex-col justify-center items-center text-center bg-[#FFBE57] border-2 border-black">
                        <div class="mt-12">
                            <p class="text-lg lg:text-4xl font-bold" v-html="$t('landingPageAcumbamail.feedbackDesc')"></p>
                        </div>
                        <div class="relative top-9 right-0 p-5 bg-white w-full max-w-xs rounded-lg border-2 border-black">
                            <h2 class="uppercase text-lg lg:text-2xl font-bold">Freemium</h2>
                        </div>
                    </section>

                    <!-- sync -->
                    <!--  <section class="flex flex-row justify-between xl:w-[1140px] mx-auto md:pr-5 lg:pr-12">
                        <div
                            class="w-full flex flex-col-reverse md:flex-row justify-center items-center md:justify-between">
                            <div class="md:w-2/4 flex justify-start items-center">
                                <img class="object-contain" src="@/assets/MercadoShops/Acumbamail/sync.png"
                                    alt="imagen descriptiva de Sincronización" />
                            </div>
                            <div
                                class="text-center md:text-right md:w-2/4 h-full flex flex-col justify-between items-center md:items-end">
                                <h2 class="text-lg lg:text-3xl xl:text-4xl font-bold">Sincronización con <br> órdenes de
                                    compra</h2>
                                <p class="mt-5 text-lg lg:text-xl font-light">
                                    integra tu eCommerce con Acumbamail de forma <br>
                                    simple y sincroniza tus órdenes de compra para <br>
                                    obtener información específica de cada contacto. <br>
                                    <span class="font-semibold">
                                        Conectá, registra y personaliza a cada cliente
                                    </span>
                                </p>
                                <button type="button"
                                    class="uppercase shadow bg-[#255AB0] text-white font-bold rounded transition-all mt-5 px-5 py-3">Prueba
                                    la versión gratis</button>
                            </div>
                        </div>
                    </section> -->

                    <!-- NPS -->
                    <!-- <section class="my-8 flex flex-row justify-between xl:w-[1140px] mx-auto md:pl-5 lg:pl-8">
                        <div class="w-full flex flex-col md:flex-row justify-center items-center md:justify-between">
                            <div
                                class="text-center md:text-left md:w-2/4 h-full flex flex-col justify-center items-center md:items-start">
                                <h2 class="mt-3 text-lg lg:text-3xl xl:text-4xl font-bold">Módulos NPS</h2>
                                <p class="mt-5 text-lg lg:text-xl font-light">
                                    Las encuestas son una herramienta muy útil para <br>
                                    conocer las necesidades de tus visitantes, y pueden <br>
                                    ayudarte a mejorar tu negocio. Crearlas y <br>
                                    publicarlas nunca ha sido tan sencillo como con el <br>
                                    editor de Acumbamail.
                                </p>
                                <br>
                                <button type="button"
                                    class="uppercase shadow bg-[#255AB0] text-white font-bold rounded transition-all mt-5 px-5 py-3">Ver
                                    servicio</button>

                            </div>
                            <div class="md:w-2/4 flex justify-end items-center">
                                <img class="flex flex-start justify-start w-[872px] h-[230px] object-contain "
                                    src="@/assets/MercadoShops/Acumbamail/module.png" alt="imagen descriptiva de MailUp" />
                            </div>
                        </div>
                    </section> -->

                    <!-- form -->
                    <!--  <section class="flex flex-row justify-between xl:w-[1140px] mx-auto md:pr-5 lg:pr-12">
                        <div
                            class="w-full flex flex-col-reverse md:flex-row justify-center items-center md:justify-between">
                            <div class="md:w-2/4 flex justify-start items-center">
                                <img class="object-contain" src="@/assets/MercadoShops/Acumbamail/form.png"
                                    alt="imagen descriptiva de Sincronización" />
                            </div>
                            <div
                                class="text-center md:text-right md:w-2/4 h-full flex flex-col justify-between items-center md:items-end">
                                <h2 class="text-lg lg:text-3xl xl:text-4xl font-bold">Crea formularios para capturar <br> y
                                    sincronizar datos en tu web</h2>
                                <p class="mt-5 text-lg lg:text-xl font-light">
                                    Suma formularios a tu tienda online y aumenta la <br>
                                    base de datos de tu negocio. Consigue clientes <br>
                                    potenciales y llega con tu mensaje cada vez a más <br>
                                    lugares.
                                    <span class="font-semibold">
                                        Un formulario, mil oportunidades.
                                    </span>
                                </p>
                                <button type="button"
                                    class="uppercase shadow bg-[#255AB0] text-white font-bold rounded transition-all mt-5 px-5 py-3">Integra
                                    tu plataforma</button>
                            </div>
                        </div>
                    </section> -->



                    <!-- footer -->
                    <section class="my-16 container-xl">
                        <div
                            class="flex flex-col gap-y-7 md:gap-y-0 md:grid md:grid-cols-3 justify-items-center place-items-center">
                            <div class="flex flex-col flex-center justify-center">
                                <img class="h-48 object-contain " src="@/assets/MercadoShops/Acumbamail/Icono-final-1.png"
                                    alt="imagen descriptiva de MailUp" />
                                <p class="text-2xl text-center" v-html="$t('landingPageAcumbamail.editorEmail')"></p>
                            </div>

                            <div class="flex flex-col flex-center justify-center">
                                <img class="h-48 object-contain ml-3"
                                    src="@/assets/MercadoShops/Acumbamail/Icono-final-2.png"
                                    alt="imagen descriptiva de MailUp" />
                                <p class="text-2xl text-center" v-html="$t('landingPageAcumbamail.enviosMes')"></p>
                            </div>

                            <div class="flex flex-col flex-center justify-center">
                                <img class="h-48 object-contain " src="@/assets/MercadoShops/Acumbamail/Icono-final-3.png"
                                    alt="imagen descriptiva de MailUp" />
                                <p class="text-2xl text-center" v-html="$t('landingPageAcumbamail.suscriptores')"></p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </TransitionFade>
    </div>
</template>

<script>
import TransitionFade from "@/components/TransitionFade"
import SelectLangs from "@/components/SelectLangs"

export default {
    name: "WelcomeAcumbamail",
    components: {
        TransitionFade,
        SelectLangs
    },
    computed: {},
    data: function () {
        return {}
    },
    mounted() { },
    methods: {}
}
</script>
